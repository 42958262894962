import React, { useState, useMemo } from 'react'

import { agentImg, profile } from '../assets/images';

import { notification } from '../assets/sound';

import Question1 from './modules/Question1';

const Conversations = ({ agent }) => {

    
    const _questions1 = [
            {
                text: "Hi 👋",
                audio: false,
                delay: 1000,
                wait: 1000
            }, 
            {
                text: `I'm ${agent} from Worker's Comp Solutions`,
                delay: 1000,
                wait: 2000
            },
            {
                text: "Want to find out if you're eligible for benefits or additional resources? Tap Yes! 😃",
                delay: 1000,
                wait: 3000,
            },
            {
                text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12" onClick={() => { setAnswer1('Yes'); setPlay(true)}}>Yes</button>,
                delay: 1000,
                wait: 4000
            }
    ];

    const _questions2 = [
        {
            text: 'Great! Let me ask you a couple of quick questions.',
            delay: 1000,
            wait: 1000
        },
    ];

    const _questions3 = [
        {
            text: '1. What is your age range?',
            delay: 1000,
            wait: 2000
        },
        {
            text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12"  onClick={() => setAnswer3('18-40')}>18-40</button>,
            delay: 1000,
            wait: 3000
        },
        {
            text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12" onClick={() => setAnswer3('41-60')}>41-60</button>,
            delay: 1000,
            wait: 4000
        },
        {
            text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12" onClick={() => setAnswer3('61+')}>61+</button>,
            delay: 1000,
            wait: 5000
        }
        
    ];

    const _questions4 = [
        {
            text: "2. Do you currently have an open Workers' Compensation case?",
            delay: 1000,
            wait: 1000
        },
        {
            text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12" onClick={() => setAnswer4('Yes')}>Yes</button>,
            delay: 1000,
            wait: 2000
        },
        {
            text: <button className="text-white font-bold bg-blue-500 rounded-full py-3 px-12" onClick={() => setAnswer4('No')}>No</button>,
            delay: 1000,
            wait: 3000
        }
    ];

    

    const congratulations = [
        {
            text: '🎉 Congratulations! 🎁',
            dellay: 1000,
            waith: 1000,
        },
        {
            
            text: "You are pre-qualified for additional resources! Confirm your shipping info over the phone to receive your benefits!",
            delay: 1000,
            wait: 2000,
        },
        {
            text: 'Tap the button below to CALL NOW. It only takes 2 minutes to finalize',
            delay: 1000,
            wait: 3000
        },
        {
            text: <a href="tel:+18883400490"  className="text-white font-bold bg-blue-500 rounded-full py-3 px-3" >+1 888-8340-0490</a>,
            delay: 1000,
            wait: 4000
        }
    ];
    

    const sorry = [
        {
            text: 'Thank you for your response. Unfortunately, our resources are designed for specific cases.',
            delay: 1000,
            wait: 1000,
        },
        {
            text: 'If you believe this was a mistake or would like to learn more, feel free to reach out!',
            delay: 1000,
            wait: 2000
        }
    ];

    const ChatClosed = () => {

        return (

            <div className="pt-2">
                <div className="flex py-5 items-center">
                    <div className="flex-grow border-t border-gray-200"></div>
                    <span className="flex-shrink mx-4 text-gray-300 text-xs">Chat closed</span>
                    <div className="flex-grow border-t border-gray-200"></div>
                </div>
            </div>

        )

    }

    const audio = new Audio( notification );

    const [play, setPlay] = useState( false );

    const [questions1, setQuestions1] = useState(_questions1);
    const [questions2, setQuestions2] = useState(_questions2);
    const [questions3, setQuestions3] = useState(_questions3);
    const [questions4, setQuestions4] = useState(_questions4);

    const [answer1, setAnswer1] = useState('');
    const [answer3, setAnswer3] = useState('');
    const [answer4, setAnswer4] = useState('');

    
    useMemo(() => {

        if (answer1 === 'Yes') {

            setQuestions1([
                {
                    text: "Hi 👋",
                    audio: false,
                    delay: 1000,
                    wait: 1000
                }, 
                {
                    text: `I'm ${agent} from Workers' Comp Solutions`,
                    delay: 1000,
                    wait: 2000
                },
                {
                    text: "Want to find out if you're eligible for benefits or additional resources? Tap Yes! 😃",
                    delay: 1000,
                    wait: 3000,
                },
            ])
        }

        if (answer3 === '18-40' || answer3 === '41-60' || answer3 === '61+') {

            setQuestions3([
                {
                    text: '1. What is your age range?',
                    delay: 1000,
                    wait: 2000
                },
            ])

        }

        if (answer4 === 'Yes' || answer4 === 'No') {

            setQuestions4([
                {
                    text: "2. Do you currently have an open Workers' Compensation case?",
                    delay: 1000,
                    wait: 1000
                },
            ])

        }

        

        if (play) {

            audio.play();

        }
    }, [answer1, answer3, answer4, play]);

    const UserProfile = ( {answer} ) => {
        return (
            <div className="flex items-start justify-end mt-2">
                <div className="ml-3 bg-blue-500 text-white p-3 rounded-lg shadow-sm w-fit">{ answer }</div>
                <img className="w-8 h-8 rounded-full ml-3" src={profile} />
            </div>
        )
    }

    const Agent = () => {

        return (

            <img src={ agentImg } className="w-8 h-8 rounded-full" />
        )

    }

    
    
    
    
    return (

        <div className="flex">
            <div className="items-end flex agent"><Agent /></div>
            <div>
                <Question1 questions={ questions1 } agent={"Amy"} audio={ audio } play={ play } />

                {
                    answer1 === 'Yes' ?

                        <div>
                            <UserProfile answer={ answer1 } />

                            <div>
                                <Question1 questions={ questions2 } audio={ audio } play={ play } />
                                <Question1 questions={ questions3 } audio={ audio } play={ play } />
                            </div>
                        </div>

                    : 

                        ''
                }

                {
                    answer3 === '18-40' || answer3 === '41-60' || answer3 === '61+' ?

                        <div>

                            <UserProfile answer={ answer3 } />

                            <Question1 questions={ questions4 } audio={ audio } play={ play } />

                        </div>
                

                    
                    : ''

                }
                {
                    answer4 === 'Yes' ?

                        <div>

                            <UserProfile answer={ answer4 } />

                            <Question1 questions={ congratulations } audio={ audio } play={ play } />

                        </div>

                    : answer4 === 'No' ?

                        <div>

                            <UserProfile answer={ answer4 } />
                    
                            <Question1 questions={ sorry } audio={ audio } play={ play } />

                            <ChatClosed />

                        </div>

                    : ''

                }
                
            </div>
        </div>

    )

}




export default Conversations